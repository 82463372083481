import { UltyDropdown } from '@ulty/ulty-ui'
import { useState } from 'react'

const days = Array.from({length: 31}, (_, d) => d + 1)

const MonthdayPicker = ({onChange}) => {
    const [selectedDay, setSelectedDay] = useState(1)

    return (
      <UltyDropdown
        fluid
        label={'Month'}
        loading={false}
        options={days.map(day => ({
            text: day,
            key: day,
            value: day,
        }))}
        onChange={(e, {value}) => {
            setSelectedDay(value)
            onChange({ day: value })
        }}
        value={selectedDay}
        multiple={false}
      />
    )
}

export { MonthdayPicker }
