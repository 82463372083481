/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { UltyDropdown } from '@ulty/ulty-ui'

const hours = Array.from(Array(24).keys())
const minutes = [0, 30]

const HourPicker = ({ hour, minute, onChange }) => {
    const handleOnChange = (value) => {
        onChange(value)
    }

    return (
      <div css={css`
          display: flex;
          column-gap: 1rem;
      `}>
          <UltyDropdown
            fluid
            label={'Heure'}
            loading={false}
            options={hours.map(hour => ({
                text: `${hour}`.padStart(2, '0'),
                key: hour,
                value: hour,
            }))}
            onChange={(e, {value}) => {
                handleOnChange({ hour: value, minute: minute })
            }}
            value={hour}
            multiple={false}
          />
          <UltyDropdown
            fluid
            label={'Minutes'}
            loading={false}
            options={minutes.map(minute => ({
                text: `${minute}`.padStart(2, '0'),
                key: minute,
                value: minute,
            }))}
            onChange={(e, {value}) => {
                handleOnChange({ hour: hour, minute: value })
            }}
            value={minute}
            multiple={false}
          />
      </div>
    )
}

export { HourPicker }
