/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import cronstrue from 'cronstrue/i18n'
import { Header } from 'semantic-ui-react'
import { UltyDropdown } from '@ulty/ulty-ui'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import useUser from '../../Shared/UserProvider/useUser'
import { getMerchantsOfCompany } from '../../../services/Company/Company.service'
import { FrequencyPicker } from './FrequencyPicker/FrequencyPicker'
import { RecipientsEditor } from './RecipientEditor/RecipientsEditor'

const PerformanceReportConfigurationForm = ({performanceReportConfiguration, onChange}) => {
    const [t] = useTranslation()
    const {user} = useUser()
    const {data: pointOfSales} = useQuery({
        queryKey: ['companies', user.companyId, 'pointofsales'],
        queryFn: () => getMerchantsOfCompany(user.companyId),
    })

    return (
      <>
          <div css={css`
              display: flex;
              justify-content: space-between;
          `}>
              <Header>
                  {cronstrue.toString(performanceReportConfiguration.cronExpression, {
                      locale: localStorage.getItem('ulty-lng').split('-')[0],
                  })}
              </Header>

              <i>Dernier envoi
                  le: {performanceReportConfiguration.lastSentAt ? new Date(performanceReportConfiguration.lastSentAt).toLocaleDateString('fr-FR', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                  }) : 'jamais'}</i>
          </div>


          <div css={css`
              display: grid;
              grid-template-columns: 1fr 1fr 1fr;
              grid-gap: 1rem;
              margin-bottom: 1rem;
          `}>
              <RecipientsEditor
                recipients={performanceReportConfiguration.recipients}
                onChange={(recipients) => {
                    onChange({
                        ...performanceReportConfiguration,
                        recipients
                    })
                }} />

              <FrequencyPicker
                cronExpression={performanceReportConfiguration.cronExpression}
                onChange={(cronExpression) => {
                  onChange({
                      ...performanceReportConfiguration,
                      cronExpression
                  })
              }} />

              <UltyDropdown
                label={'Points de ventes'}
                icon='point'
                labeled
                button
                className='icon'
                loading={!pointOfSales}
                options={pointOfSales ? pointOfSales.map(pos => ({
                    text: pos.name,
                    key: pos.id,
                    value: pos.id,
                })) : []}
                onChange={(e, { value }) => {
                    onChange({
                        ...performanceReportConfiguration,
                        posIds: value,
                        pointOfSales: value.map(v => ({ id: v }))
                    })
                }}
                value={performanceReportConfiguration.pointOfSales.map(pos => pos.id)}
                multiple={true}
              />
          </div>
      </>
    )
}

export { PerformanceReportConfigurationForm }
