/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { UltyDropdown } from '@ulty/ulty-ui'
import { HourPicker } from './HourPicker'
import { WeekdayPicker } from './WeekdayPicker'
import { MonthdayPicker } from './MonthdayPicker'

const frequencies = ['daily', 'weekly', 'monthly']

const cronObjectToCronString = (cronObject) => {
    const { minute, hour, day, month, weekday } = cronObject
    return `${minute} ${hour} ${day} ${month} ${weekday}`
}

const cronStringToCronObject = (cronString) => {
    const [minute, hour, day, month, weekday] =  cronString.split(' ')

    return {
        minute,
        hour,
        day,
        month,
        weekday
    }
}

const cronExpressionToFrequency = (cronExpression) => {
    const {
        day,
        weekday
    } = cronStringToCronObject(cronExpression)

    if (day !== '*') {
        return 'monthly'
    }

    if (weekday !== '*') {
        return 'weekly'
    }

    return 'daily'
}

const initialDaily = {
    minute: '0',
    hour: '10',
    day: '*',
    month: '*',
    weekday: '*'
}

const initialWeekly = {
    ...initialDaily,
    day: '*',
    month: '*',
    weekday: '1'
}

const initialMonthly = {
    ...initialDaily,
    day: '1',
    month: '*',
    weekday: '*'
}

const initialCronObject = {
    'daily': initialDaily,
    'weekly': initialWeekly,
    'monthly': initialMonthly,
}


const FrequencyPicker = ({ cronExpression, onChange}) => {
    const cronObject = cronStringToCronObject(cronExpression)
    const frequency = cronExpressionToFrequency(cronExpression)

    const onHourChange = ({hour, minute}) => {
        const newValue = {
            ...cronObject,
            hour,
            minute
        }
        onChange(cronObjectToCronString(newValue))
    }

    const onWeekdayChange = ({ weekday }) => {
        const newValue = {
            ...cronObject,
            weekday,
        }
        onChange(cronObjectToCronString(newValue))
    }

    const onMonthdayChange = ({day}) => {
        const newValue = {
            ...cronObject,
            day,
        }
        onChange(cronObjectToCronString(newValue))
    }

    return (
      <div css={css`
          display: flex;
          flex-direction: column;
      `}>
          <UltyDropdown
            label={'Fréquence'}
            loading={false}
            fluid
            icon='calendar'
            labeled
            button
            className='icon'
            options={frequencies.map(frequency => ({
                text: frequency,
                key: frequency,
                value: frequency,
            }))}
            onChange={(e, {value}) => {
                onChange(cronObjectToCronString(initialCronObject[value]))
            }}
            value={frequency}
            multiple={false}
          />

          {frequency === 'daily' &&
            <HourPicker hour={+cronObject.hour} minute={+cronObject.minute} onChange={onHourChange} />
          }

          {frequency === 'weekly' &&
            <div css={css`
                display: flex;
                column-gap: 1rem;
            `}>
                <WeekdayPicker onChange={onWeekdayChange} />
                <HourPicker hour={+cronObject.hour} minute={+cronObject.minute} onChange={onHourChange} />
            </div>
          }

          {frequency === 'monthly' &&
            <div css={css`
                display: flex;
                column-gap: 1rem;
            `}>
                <MonthdayPicker onChange={onMonthdayChange}/>
                <HourPicker hour={+cronObject.hour} minute={+cronObject.minute} onChange={onHourChange}/>
            </div>
          }
      </div>
    )
}

export { FrequencyPicker }
